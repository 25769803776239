<template>
	<div>
		<div class="location-nav">
			<div class="links">
				<div class="name"><span class="fa fa-th-large"></span>Account</div>
				<router-link to="/account/order-history">Order History</router-link>
				<router-link to="/account/profile">Profile</router-link>
				<router-link v-if="currentAccount.type === 'STANDARD'" to="/account/password">Password</router-link>
			</div>
		</div>
		<router-view v-bind="$props"></router-view>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {

	name: 'AccountPage',

	computed: mapGetters([ 'currentAccount' ]),

	methods: {

		logout() {
			this.$store.dispatch('logout');
			this.$router.push('/login/customer');
		},

	},

};

</script>
